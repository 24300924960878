<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box" :gutter="10">
      <el-tabs v-model="incomingState">
        <el-tab-pane label="进件阶段" name="1"></el-tab-pane>
        <el-tab-pane label="报备阶段" name="2"></el-tab-pane>
        <el-tab-pane label="实名阶段" name="3"></el-tab-pane>
      </el-tabs>
      <el-tabs v-if="incomingState==='1'" v-model="incomingSubState" @tab-click="handleIncomingStateClick">
        <el-tab-pane v-for="(item,index) in incomingStateTab" :key="index" :label="item.label" :name="item.value"></el-tab-pane>
      </el-tabs>
      <el-tabs v-if="incomingState==='2'" v-model="reoprtedState" @tab-click="handlereoprtedStateClick">
        <el-tab-pane v-for="(item,index) in reoprtedStateTab" :key="index" :label="item.label" :name="item.value"></el-tab-pane>
      </el-tabs>
      <el-tabs v-if="incomingState==='3'" v-model="applymentState" @tab-click="handleApplymentClick">
        <el-tab-pane v-for="(item,index) in applymentStateTab" :key="index" :label="item.label" :name="item.value"></el-tab-pane>
      </el-tabs>
      <el-row class="table-box">
        <el-table :data="tableData" style="width: 100%" fit>
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column prop="hotelName" label="酒店名称">
          </el-table-column>
          <el-table-column prop="updateTime" label="更新时间">
          </el-table-column>
          <el-table-column prop="incomingState" label="状态">
            <template slot-scope="scope">
              <span>{{scope.row.incomingState | fliterState }}</span>

            </template>
          </el-table-column>
          <el-table-column prop="action" label="操作">
            <template slot-scope="scope">
              <el-button v-if="scope.row.incomingState==='01'" type="text" @click="income(scope.row)"> PC进件 </el-button>
              <el-button v-if="scope.row.incomingState==='03'" type="text" @click="getMerchantInfo(scope.row)"> 查看信息 </el-button>
              <!-- <el-button type="text" @click="getMerchantInfo(scope.row)"> 查看信息 </el-button> -->

              <el-button v-if="scope.row.incomingState==='09'" type="text" @click="income(scope.row)"> 重新进件 </el-button>

              <el-button v-if="scope.row.incomingState==='04'" type="text" @click="merchantReport(scope.row)"> 报备确认 </el-button>

              <el-button v-if="scope.row.incomingState==='02'" type="text" @click="getMerchantInfo(scope.row)"> 查看信息 </el-button>
              <el-button v-if="scope.row.incomingState==='05'" type="text" @click="merchantReport(scope.row)"> 重新报备 </el-button>
              <el-button v-if="scope.row.incomingState==='06'" type="text" @click="realName(scope.row)"> 实名验证 </el-button>
              <!-- <el-button type="text" @click="realName(scope.row)"> 实名验证 </el-button> -->

              <el-button v-if="scope.row.incomingState==='07'" type="text"> 审核中 </el-button>
              <el-button v-if="scope.row.incomingState==='13'" type="text" @click="realName(scope.row)"> 重新实名验证 </el-button>
              <el-popover :ref="`popover-${scope.row.id}`" placement="top" title="查看原因">
                <p>{{reasonContent}}</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" @click="$refs['popover-' + scope.row.id].showPopper = false">取消</el-button>
                </div>
                <!-- <el-button slot="reference" type="text" @click="seeReason(scope.row)"> 查看原因 </el-button> -->
                <el-button v-if="scope.row.incomingState==='13'|| scope.row.incomingState==='14'" slot="reference" type="text" @click="seeReason(scope.row)"> 查看原因 </el-button>

              </el-popover>
              <el-button v-if="scope.row.incomingState==='14'||scope.row.incomingState==='10'||scope.row.incomingState==='11'" type="text" @click="downLoadQrcode(scope.row)"> 下载二维码 </el-button>
              <!-- <el-button type="text" @click="getQrcode(scope.row)"> 获取二维码 </el-button> -->
              <!-- <el-button type="text" @click="downLoadQrcode(scope.row)"> 下载二维码 </el-button> -->

              <!-- downLoadQrcode -->
              <el-button v-if="scope.row.incomingState==='15'" type="text" disabled> 作废 </el-button>
              <el-button v-if="scope.row.incomingState==='12'" type="text" disabled> 完成 </el-button>

            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
    <!-- 报备确认 -->
    <el-dialog title="报备确认" :visible.sync="merchantReportVisible" width="30%">
      <el-form :model="merchantReportForm" ref="merchantReportForm" label-width="150px">
        <el-form-item label="小程序appId" prop="appletappid" :rules="[
      { required: true, message: '小程序appId不能为空'},
    ]">
          <el-input v-model="merchantReportForm.appletappid"></el-input>
        </el-form-item>
        <el-form-item prop="apppubappid" label="公众号appId">
          <el-input v-model="merchantReportForm.apppubappid"></el-input>
        </el-form-item>
        <el-form-item prop="jsapipath1" label="公众号授权目录1">
          <el-input v-model="merchantReportForm.jsapipath1"></el-input>
        </el-form-item>
        <el-form-item prop="jsapipath2" label="公众号授权目录2">
          <el-input v-model="merchantReportForm.jsapipath2"></el-input>
        </el-form-item>
        <el-form-item prop="jsapipath3" label="公众号授权目录3">
          <el-input v-model="merchantReportForm.jsapipath3"></el-input>
        </el-form-item>
        <el-form-item prop="jsapipath4" label="公众号授权目录4">
          <el-input v-model="merchantReportForm.jsapipath4"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeMerchantReportDialg">取 消</el-button>
        <el-button type="primary" @click="savemerchantReport">确 定</el-button>
      </span>
    </el-dialog>
    <!--  -->
  </div>
</template>

<script>
import {
  getPage,
  saveMerchantReport,
  getQrcode,
  downLoadQrcode,
  wxAuthById,
  getMerchantINfo,
} from '@/api/system/ysManagement'
import { downloadFile } from '@/api/download'
export default {
  filters: {
    fliterState(state) {
      let incomingState = ''
      switch (state) {
        case '01':
          incomingState = '未进件'
          break
        case '03':
          incomingState = '审核中'
          break
        case '09':
          incomingState = '审核失败'
          break
        case '04':
          incomingState = '待报备'
          break
        case '02':
          incomingState = '审核中'
          break
        case '05':
          incomingState = '审核失败'
          break
        case '06':
          incomingState = '待实名'
          break
        case '07':
          incomingState = '审核中'
          break
        case '13':
          incomingState = '审核驳回'
          break
        case '14':
          incomingState = '已冻结'
          break
        case '15':
          incomingState = '已作废'
          break
        case '10':
          incomingState = '待确认联系信息'
          break
        case '11':
          incomingState = '待账户验证'
          break
        case '12':
          incomingState = '实名完成'
          break
        default:
          break
      }
      return incomingState
    },
  },
  data() {
    return {
      crumbs: new Map([['系统管理'], ['银盛子商户管理']]),
      incomingState: '1',
      incomingSubState: '1',
      reoprtedState: '1',
      applymentState: '1',
      incomingStateTab: [
        { label: '全部', name: 'all', value: '1' },
        { label: '未进件', name: 'NOT_DELIVERED', value: '01' },
        { label: '审核中', name: 'INCOMING_REVIEW', value: '03' },
        { label: '审核失败', name: 'INCOMING_REVIEW_FAILED', value: '09' },
      ],
      reoprtedStateTab: [
        { label: '全部', name: 'all', value: '2' },
        { label: '待报备', name: 'TO_BE_REPORTED', value: '04' },
        { label: '审核中', name: 'UNDER_REVIEW', value: '02' },
        { label: '审核失败', name: ' REPORT_FAILED', value: '05' },
      ],
      applymentStateTab: [
        { label: '全部', name: 'all', value: '3' },
        { label: '待实名', name: 'TO_BE_AUTH', value: '06' },
        {
          label: '实名中',
          name: 'APPLYMENT_STATE_WAITTING_FOR_AUDIT',
          value: '07',
        },
        { label: '实名完成', name: 'APPLYMENT_STATE_PASSED', value: '12' },
      ],
      activeState: 'all',
      tableData: [
        // { hotelName: '达易住酒店', updateTime: '20220701', state: '未进件' },
      ],
      merchantReportVisible: false, // 报备
      merchantReportForm: {
        appletappid: '',
        apppubappid: '',
        jsapipath1: '',
        jsapipath2: '',
        jsapipath3: '',
        jsapipath4: '',
        jsapipath5: '',
        merchantIncomingId: '',
      },
      realnameVisible: false, // 实名
      reasonVisible: false, // 查看原因
      reasonContent: '',
      params: {
        limit: 10,
        page: 1,
        incomingState: '1',
      },
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    'params.incomingState'() {
      this.getList()
    },
    incomingState(val) {
      console.log('incomingState', val)
      this.params.incomingState = val
      // this.activeState = 'all'
      this.incomingSubState = '1'
      this.reoprtedState = '2'
      this.applymentState = '3'
    },
    activeState(val) {},
  },

  methods: {
    income(hotelInfo) {
      // this.$router.push('/ys_management/pc_incoming?')
      // this.$router.push({
      //   name: 'pcIncoming',
      //   params: hotelInfo,
      // })
      this.$router.push({
        path: '/ys_management/pc_incoming',
        query: { id: hotelInfo.id, action: 'edit' },
      })
    },
    // 获取列表
    async getList() {
      try {
        const res = await getPage(this.params)
        console.log(res)
        this.tableData = res.records
      } catch (error) {
        console.log(error)
      }
    },

    handleIncomingStateClick(tab, event) {
      this.params.incomingState = tab.name
    },
    handlereoprtedStateClick(tab, event) {
      this.params.incomingState = tab.name
    },
    handleApplymentClick(tab) {
      this.params.incomingState = tab.name
    },
    // 报备确认
    merchantReport(rowData) {
      this.merchantReportVisible = true
      this.merchantReportForm.merchantIncomingId = rowData.merchantIncomingId
    },
    savemerchantReport() {
      this.$refs.merchantReportForm.validate((valid) => {
        if (valid) {
          saveMerchantReport(this.merchantReportForm).then((res) => {
            console.log(res)
            if (res.success) {
              this.merchantReportVisible = false
              this.$message.success('操作成功')
            }
          })
        }
      })
    },
    closeMerchantReportDialg() {
      this.merchantReportVisible = false
      this.$refs.merchantReportForm.resetFields()
    },
    // 实名
    realName(rowData) {
      this.$confirm('是否提交当前信息进行实名验证?', '实名验证确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          try {
            const res = await wxAuthById({ id: rowData.id })
            console.log(res)
            this.$message.success('实名验证成功')
            this.getList()
          } catch (error) {
            console.log('实名验证失败：', error)
          }
        })
        .catch(() => {})
    },
    // 查看信息
    getMerchantInfo(rowData) {
      this.$router.push({
        path: '/ys_management/pc_incoming',
        query: { action: 'info', hotelId: rowData.hotelId, id: rowData.id },
      })
      // try {
      //   const res = await getMerchantINfo({ hotelId: rowData.hotelId })
      //   this.$router.push({
      //     path: '/ys_management/pc_incoming',
      //     query: { action: 'info', hotelId: rowData.hotelId },
      //   })
      //   console.log('商户信息：', res)
      // } catch (error) {
      //   console.log('查看商户信息失败：', error)
      // }
    },
    // 查看原因
    seeReason(rowData) {
      // console.log(rowData)
      getMerchantINfo({ hotelId: rowData.hotelId }).then((res) => {
        if (res.success) {
          this.reasonContent = res.rejectReason
        }
      })
    },
    // 下载二维码
    async getQrcode(rowData) {
      try {
        const res = await getQrcode({ id: rowData.id })
        const imgurl = `data:image/png;base64,${res.data}`
        let a = document.createElement('a')
        a.href = imgurl
        a.setAttribute('download', 'qrcode')
        a.click()
      } catch (error) {
        console.log(error)
      }
    },
    downLoadQrcode(rowData) {
      downloadFile('/hotel/merchantincoming/downloadQrcode/' + rowData.id)
      // try {
      //   const res = await this.$axios.get(
      //     '/hotel/merchantincoming/downloadQrcode/' + rowData.id,
      //     {},
      //     {},
      //     'blob'
      //   )
      //   let blob = new Blob([res])
      //   // let url = URL.createObjectURL(blob)

      //   // const res = await downLoadQrcode({ id: rowData.id })
      //   console.log(res)
      //   const dlink = document.createElement('a')
      //   dlink.download = 'qrcode.png'
      //   dlink.style.display = 'none'
      //   dlink.href = URL.createObjectURL(blob)
      //   document.body.appendChild(dlink)
      //   dlink.click()
      //   URL.revokeObjectURL(dlink.href) // 释放URL 对象
      //   document.body.removeChild(dlink)
      // } catch (error) {
      //   console.log(error)
      // }
    },
  },
}
</script>

<style></style>
