import axios from 'axios';
import Vue from "vue";
import { Message } from 'element-ui'

const service = axios.create({
    returnAxiosResponse: true,
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000,
    method: 'get',
    responseType: 'blob',
    headers: {
        Authorization: sessionStorage.getItem('access_token') || '',
        clientType: "web"
    }
})

// 拦截一下
service.interceptors.response.use(
    response => {
        return Promise.resolve(response)
    },
    error => {
        let err = error.response
        Message({ message: '下载失败', type: 'error' })
        return Promise.reject(err)
    }
)
/**
 * 下载文件
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFile(url, parameter) {
    return service({
        url: url,
    })
}

/**
 * 下载文件
 * @param url 接口地址
 * @param fileName 文件名 后端返回有就不穿，可选
 * @param parameter 下载文件一般没有参数，可选
 * @returns {*}
 */
export function downloadFile(url, fileName, parameter) {
    return downFile(url, parameter).then((response) => {
        const { headers, data, config } = response
        if (!data || data.size === 0) {
            Vue.prototype['$message'].warning('文件下载失败')
            return
        }
        if (!fileName) {
            fileName = decodeURI(headers["content-disposition"].split("filename=")[1])
            fileName = fileName.slice(0, fileName.indexOf('.png') + 4)
            // console.log(fileName.indexOf('.png'));
        }
        if (typeof window.navigator.msSaveBlob !== 'undefined') {  // 主要针对ie
            window.navigator.msSaveBlob(new Blob([data]), fileName)
        } else {
            let url = window.URL.createObjectURL(new Blob([data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
        }
    })
}
